.Modal {
    height: 1000px;
    width: 100vw;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;
    padding-top: 60px;
    a, p {
        width: 80vw;
        color: #fff;
        display: block;
        text-align: center;
        margin: 20px auto;
        font-size: 26px;
        font-weight: 400;
    }
}

