@import "_mixins.scss";
@import "_variables.scss";

.MobileNavbar {
    @include flex(space-between, center);
    height: 60px;
    width: 100vw;
    box-shadow: $bottomShadow;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    svg {
        height: 40px;
        width: auto;
        margin-left: 20px;
    }
    .MobileIcon {
        svg {
            color: #fff;
            margin-right: 20px;
        }
    }
}