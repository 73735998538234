@import "_variables.scss";

.Footer {
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 80px;
    background-color: #000;
    svg {
        margin: 60px 0;
    }
    .Copyright {
        margin: 20px 0 0 0;
        padding: 10px 0;
        background-color: $mainColor;
        color:#fff;
    }
}

@media (max-width: 1000px) {
    .Footer {
        margin: 40px 0;
        svg {
            width: 80%;
            height: auto;
            margin: 30px auto;
        }
    }
}