@import "_variables.scss";
@import "_mixins.scss";



#MainContent {
    height: 80vh;
    margin: 100px 40px 20px 40px;
    border-radius: 20px;
    box-shadow: $boxShadow;
    background-color: #000;
    .Carousel {
        height: 80vh;
        color: #fff;
        text-align: center;
        border-radius: 20px;
        h2 {
            @include flex(center, center);
            height: 20vh;
            max-width: 1200px;
            margin: 30vh auto 0 auto;
            font-size: 40px;
            font-weight: 800;
            background-color: #ccc;
            opacity: 0.7;
        }
    }
    .Cestna {
        background: url("../assets/images/kamion4.jpg"), url("../assets/images/kamion1.jpg"); 
        background-position: left, right;
        background-repeat: no-repeat;
        background-size: 50%, 50%;
    }
    .Tovar {
        background: url("../assets/images/kamion6.jpg"), url("../assets/images/kamion7.jpg"); 
        background-position: left, right;
        background-repeat: no-repeat;
        background-size: 50%, 50%;
    }
}
.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 30px;
    height: 10px;
    border-radius: 30%;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
    display: block;
    width: 30px;
    height: 10px;
    border-radius: 30%;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #000;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
    opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
    width: 50px;
}
.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    width: 50px;
    background: $mainColor;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
    opacity: 1;
}

@media (max-width: 1000px) {
    #MainContent {
        height: 600px;
        margin: 130px 30px 20px 30px;
        .Carousel {
            height: 400px;
            h2 {
                @include flex(center, center);
                height: 100px;
                margin: 30vh auto 0 auto;
                font-size: 20px;
                font-weight: 800;
                background-color: #ccc;
                opacity: 0.7;
            }
        }
        .Cestna {
            background: url("../assets/images/kamion1.jpg"); 
            background-position: center;
            background-size: 90vw;
        }
        .Tovar {
            background: url("../assets/images/kamion6.jpg"); 
            background-size: 100vh;
            background-position: center;
        }
    }
}