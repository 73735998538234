@import '~antd/dist/antd.css';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "DesktopNavbar.scss";
@import "MobileNavbar.scss";
@import "Main.scss";
@import "Services.scss";
@import "Contact.scss";
@import "Footer.scss";
@import "Modal.scss";
@import "Gallery.scss";

body {
    width: 100%;
    font-family: $mainFont;
    background-color: #000!important;
    overflow-x: hidden;
}

@media (max-width: 1000px) {
    body {
        margin: 0;
        width: 100vw;
    }
}