@import "_variables.scss";

#Services {
    height: 800px;
    padding-top: 100px;
    background: rgb(0,0,0);
    background: linear-gradient(184deg, rgba(0,0,0,1) 76%, rgba(0,116,46,1) 76%);
    color: #fff;
    h2 {
        color: #fff;
        text-align: center;
        font-size: 46px;
        margin: 0 auto 30px auto;
        max-width: 1400px;
        font-weight: 800;
    }
    h3 {
        font-size: 26px;
        color: #fff;
        text-align: center;
        span svg {
            color: $mainColor;
        }
    }
}

@media (max-width: 1000px) {
    #Services {
        padding-top: 0;
        height: 500px;
        background: linear-gradient(184deg, rgba(0,0,0,1)86%, rgba(0,116,46,1) 86%);
        h2 {
            font-size: 20px;
            margin: 0 20px 20px 60px;
            text-align: left;
        }
        h3 {
            font-size: 16px;
            margin: 0 60px 20px 60px;
            text-align: left;
        }
    }
}