@import "_variables.scss";
@import "_mixins.scss";

.Gallery {
    margin-top: 20px;
    a{
        position: fixed;
        top: 20px;
        right:20px;
        color: #fff;
        font-size: 40px;
        z-index: 100;
    }
    a:hover {
        cursor: pointer;
        color: $mainColor;
    }
}

@media (max-width: 1000px) {
    .Gallery {
        margin: 80px 10px;
        .image-gallery-image {
            @include flex(center, center);
        }
    }
}

