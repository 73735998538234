@import "_variables.scss";
@import "_mixins.scss";

.Navbar {
    @include flex(space-between, center);
    height: 60px;
    width: 100vw;
    box-shadow: $bottomShadow;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    .LogoNavbar {
        height: 40px;
        width: auto;
        margin: auto 20px;
    }
    h2 {
        margin: auto 0;
        color: #fff;
        font-size: 20px;
    }
    .Navigation {
        margin: auto 20px;
        color: #fff;
        p, a {
            margin: auto 0;
            font-size: 16px;
            font-weight: 400;
            margin: auto 10px;
            color: #fff;
        }
        a:hover, p:hover {
            cursor: pointer;
            color: $mainColor;
        }
        span svg {
            margin: auto  10px auto 0;
            height: 20px;
            width: 20px;
        }
    }    
}

@media (max-width: 1000px) {
    
}
