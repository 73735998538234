@import "_mixins.scss";

#Contact {
    height: 700px;
    background: rgb(0,116,46);
    background: linear-gradient(176deg, rgba(0,116,46,1) 80%, rgba(0,0,0,1) 80%);
    h3 {
        font-size: 46px;
        font-weight: 800;
        margin: auto 0 20px 100px;
        color: #fff;
    }
    p {
        margin-left: 100px;
        font-size: 20px;
        color: #fff;
    }
}

@media (max-width: 1000px) {
    #Contact {
        height: 800px;
        background: linear-gradient(176deg, rgba(0,116,46,1) 95%, rgba(0,0,0,1) 95%);
        .ContactInfo {
            height: 300px;
            h3 {
                margin: 0 auto 20px 60px;
                font-size: 20px;
            }
            p {
                margin: 0 auto 0 60px;
                text-align: justify;
            }
        }
        .Map {
            height: 400px;
            @include flex(center, flex-start);
        }
    }
}
